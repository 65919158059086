import React, { useContext } from 'react'
import { SmallerContainer } from 'components/common'
import { ThemeContext } from 'providers/ThemeProvider'
import { MagicalButton, Wrapper, Flex } from './styles'
import { Link } from 'gatsby'

export const Intro = () => {
  const { theme } = useContext(ThemeContext)
  return (
    <Wrapper theme={theme} as={SmallerContainer}>
      <h1>I'm Akramjon Mamasoliev</h1>
      <p>
        I'm a fullstack JavaScript Developer experienced in React, React Native
        TypeScript and NodeJS.
        <br /> I help companies create positive impact to the world by crafting
        software using JavaScript technologies.
      </p>
      <Flex>
        <MagicalButton
         as={Link}
         to="/resume"
        >
          View resume
        </MagicalButton>
        <MagicalButton as={Link} to="/blog/">
          Read blogs
          {/* Get in touch */}
        </MagicalButton>
      </Flex>
    </Wrapper>
  )
}

// const Link = styled.a``
