import { Layout, SEO } from 'components/common'
import {
  Intro,
  Services, Skills,
  // Github,
  // RecentTalks,
  Testimonials, Work
} from 'components/landing'
import React from 'react'

export default () => (
  <Layout>
    <SEO title="Personal Website!" type="Organization" />
    <Intro />
    <Skills />
    <Work />
    <Services />
    {/* <Github /> */}
    {/* <Popular /> */}
    <Testimonials />
    {/* <RecentTalks /> */}
  </Layout>
)
